import { createSlice } from '@reduxjs/toolkit';

export const defaultColumns = {
  SSV: false,
  addonsBeforeCheckout: false,
  aliceChatServiceId: false,
  aliceId: false,
  aliceKey: false,
  chatServiceId: false,
  country: false,
  heroImg: false,
  ideasId: false,
  kitchenCutCryptPassword: false,
  noniusId: false,
  reservationEmailAddress: false,
  reservationEmailAddressPwd: false,
  reservationEmailUsername: false,
  shortName: false,
  signatureVillas: false,
  silverwareCryptPassword: false,
  silverwareId: false,
  synxis: false,
  timezone: false,
  workdayId: false,
};
const initialState = {
  visibleColumns: defaultColumns,
};

export const ProductColumnsSlice = createSlice({
  name: 'product-columns',
  initialState,
  reducers: {
    setColumns: (state, action) => {
      state.visibleColumns = action.payload;
    },
  },
});

export const { setColumns } = ProductColumnsSlice.actions;
export default ProductColumnsSlice.reducer;
